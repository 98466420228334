import { useDropzone } from "react-dropzone";
import styles from "./upload-image.module.scss";
import { useCallback, useMemo } from "react";

export default function UploadImage({setSelectedImage, selectedImage}) {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach((file) => {
      setSelectedImage(file);
    });
  }, [setSelectedImage]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ 
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    }
  });

  const style = useMemo(
    () => ({
      ...(isDragAccept ? { borderColor: "#00e676" } : {}),
      ...(isDragReject ? { borderColor: "#ff1744" } : {})
    }),
    [isDragAccept, isDragReject]
  );

  const cancelImage = (e) => {
    e.stopPropagation();
    setSelectedImage(null);
  }

  return (
    <div>
      <div className={styles.dropzone} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className={styles.dropzoneContent} style={{width: selectedImage ? 265.5 : 182}}>
          {selectedImage ? (
            <div className={styles.uploadedImage}>
              <p className={styles.name}>{selectedImage.name}</p>
              <div className={styles.cancel} onClick={cancelImage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path d="M0.943364 8.26176L0.117188 7.43559L3.42189 4.13088L0.117188 0.826176L0.943364 0L4.24807 3.3047L7.55277 0L8.37895 0.826176L5.07424 4.13088L8.37895 7.43559L7.55277 8.26176L4.24807 4.95706L0.943364 8.26176Z" fill="#9F9F9F"/>
                </svg>
              </div>
              <div className={styles.percentBlock}>
                <div className={styles.percent} style={{width: '100%'}}></div>
              </div>
              <div className={styles.uploadStatus}>
                <p className={styles.mb}>{(selectedImage.size / 1024).toFixed(1)} of {(selectedImage.size / 1024).toFixed(1)} Mb</p>
                <p className={styles.percent}>Upload done... <span>100%</span></p>
              </div>
            </div>
          ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                <g opacity="0.4">
                <path d="M21.5423 31.3359V15.3755L16.4507 20.4672L13.709 17.6276L23.5007 7.83594L33.2923 17.6276L30.5506 20.4672L25.459 15.3755V31.3359H21.5423ZM11.7507 39.1693C10.6736 39.1693 9.75152 38.7858 8.98451 38.0187C8.21749 37.2517 7.83398 36.3297 7.83398 35.2526V29.3776H11.7507V35.2526H35.2507V29.3776H39.1673V35.2526C39.1673 36.3297 38.7838 37.2517 38.0168 38.0187C37.2498 38.7858 36.3277 39.1693 35.2507 39.1693H11.7507Z" fill="#EB4744"/>
                </g>
              </svg>
              {isDragActive ? (
                <p className={styles.text}>Drop file here ...</p>
              ) : (
                <p className={styles.text}>Drag and drop or <span>browse</span> your files</p>
              )}
            </>
          )}
        </div>
      </div>
      <p className={styles.warrningText}>Supported file formats: png, jpeg, jpg</p>
    </div>
  );
}
