import axios from 'axios';
import { baseURL } from '../constants/urls'

const axiosApi = axios.create({ baseURL });

axiosApi.interceptors.request.use(
    async(config) => {
        let accessToken = await localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken
        }
        return config;
    },
    (error) => { throw error }
);

axiosApi.interceptors.response.use(
    (res) => {
      return res.data
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken')
      }

      if (error.response.status === 403) {
        return null
      }
        console.log(error); 
        throw error;
    }
);

export default axiosApi;