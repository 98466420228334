import React, { useEffect,useState } from 'react';
import Login from '../../components/login';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import axiosApi from '../../services/api';
import styles from './verify_email_success.module.scss';

function VerifyEmailSuccess() {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            (async() => {
                await axiosApi.get(`/auth/verify?token=${token}`).catch(() => {
                    // navigate('/sign_in');
                }).finally(() => {
                    queryParams.delete('token')
                    navigate({
                        search: queryParams.toString(),
                    });
                    setIsLoading(false);
                })
            })()
        } else {
            // navigate('/sign_in');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Login>
            {isLoading && <Loading />}
            <div className={styles.title}>Email Verified</div>
            <div className={styles.description}>Thank you your email has been verified</div>
            <Link className={styles.button} to="/sign_in">Login</Link>
        </Login>
    );
}

export default VerifyEmailSuccess;