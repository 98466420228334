import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Home from '../pages/Home';
import VerifyEmailSuccess from '../pages/VerifyEmailSuccess';
import SendVerify from '../pages/SendVerify';
import AuthGuard from '../components/authGuard';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign_in" element={<AuthGuard component={()  => <SignIn />} />} />
        <Route path="/send_verify" element={<AuthGuard component={()  => <SendVerify />} />} />
        <Route path="/verify" element={<AuthGuard component={()  => <VerifyEmailSuccess />} />} />
        <Route path="/sign_up" element={<AuthGuard component={()  => <SignUp />} />} />
        <Route path="/dashboard" element={<AuthGuard isAuth component={()  => <Dashboard />} />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
