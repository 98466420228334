import React from 'react';
import styles from './login.module.scss';

function Login({children}) {
    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <img className={styles.logo} src={require('../../assets/images/logo.png')} alt='Yearbook' />
                {children}
            </div>
            <div className={styles.imgContainer}>
                <img src={require('../../assets/images/login-background.png')} alt='Yearbook' />
            </div>
        </div>
    );
}

export default Login;
