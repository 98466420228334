export let tokens = {
    accessToken: null,
    //refreshToken: null
}

function saveTokens(newTokens) {
    tokens = {
        ...tokens,
        ...newTokens
    }
}

export async function setTokens(newToken) {
    try {
        await localStorage.setItem('accessToken', newToken)
        //await localStorage.setItem('refreshToken', refreshToken)
        saveTokens(newToken)
    }
    catch (error) {
        throw error
    }
}

export async function removeTokens() {
  try {
    await localStorage.removeItem('accessToken');
    saveTokens({ accessToken: null })
  }
  catch (error) {
    throw error
  }
}

export function checkTokens() {
    try {
      return localStorage.getItem('accessToken')
    }
    catch (error) {
        throw error
    }

}
