import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkTokens } from '../../utils/tokens'

const AuthGuard = ({component, isAuth}) => {
  const accessToken = checkTokens();
  if (isAuth) {
    return accessToken ? component() : <Navigate to="/sign_in" />;
  } else {
    return !accessToken ? component() : <Navigate to="/dashboard" />;
  }
};

export default AuthGuard
