import React from 'react';
import ReactLoading from 'react-loading';
import styles from './loading.module.scss';

export default function Loading() {
    return (
        <div className={styles.loadingContainer}>
            <ReactLoading type={'spinningBubbles'} color="#EB4744" height={68} width={68} />
        </div>
    );
}
