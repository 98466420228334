import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Login from '../../components/login';
import Loading from '../../components/loading';
import styles from './sign_up.module.scss';
import axiosApi from '../../services/api';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    name: yup.string().required('This field is required'),
    email: yup.string().email('Enter a valid email').required('This field is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('This field is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('This field is required'),
    agreeTerms: yup.boolean().oneOf([true], 'You must agree to the Terms of Service and Privacy Policy'),
});

function SignUp() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();

    const onSubmit = async(data) => {
        try {
            setIsLoading(true);
            const { agreeTerms, confirmPassword, ...formData } = data;
            await axiosApi.post('/auth/register', formData).then(res => {
                if (res) {
                    navigate(`/send_verify?email=${formData.email}`);
                }
            }).catch((error) => {
                let errorMessage = '';
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                } else {
                    errorMessage = 'Server error.'
                }
                toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).finally(() => {
                setIsLoading(false);
            });
        } catch (error) {
            toast.error('An error occurred, please try again.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(true);
        }
    }

    return (
        <Login>
            {isLoading && <Loading />}
            <div className={styles.title}>Sign Up</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.inputGroup}>
                    <div className={styles.textField}>
                        <label>Name *</label>
                        <input {...register('name')} type='text' />
                        {errors.name && <p className={styles.error}>{errors.name.message}</p>}
                    </div>
                    <div className={styles.textField}>
                        <label>Email *</label>
                        <input {...register('email')} type='text' />
                        {errors.email && <p className={styles.error}>{errors.email.message}</p>}
                    </div>
                    <div className={styles.textField}>
                        <label>Password</label>
                        <input {...register('password')} className={styles.password} autoComplete="on" type={showPassword ? 'text' : 'password'} />
                        <div className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z" fill="#EB4744"/>
                                </svg>
                            ) : (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#EB4744"/>
                                </svg>
                            )}
                        </div>
                        {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                    </div>
                    <div className={styles.textField}>
                        <label>Confirm Password</label>
                        <input {...register('confirmPassword')} className={styles.password} autoComplete="on" type={showConfirmPassword ? 'text' : 'password'} />
                        <div className={styles.togglePassword} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z" fill="#EB4744"/>
                                </svg>
                            ) : (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#EB4744"/>
                                </svg>
                            )}
                        </div>
                        {errors.confirmPassword && <p className={styles.error}>{errors.confirmPassword.message}</p>}
                    </div>
                </div>
                <div className={styles.checkboxField}>
                    <input {...register('agreeTerms')} type="checkbox" id="terms"/>
                    <label for="terms">
                        <span className={styles.checkbox}></span>
                        <p>
                            By registering, you agree to the <span className={styles.link}>Terms of Service</span> and <span className={styles.link}>Privacy Policy</span>. *
                        </p>
                    </label>
                </div>
                {errors.agreeTerms && <p className={styles.error} style={{marginTop: 5}}>{errors.agreeTerms.message}</p>}
                <div className={styles.buttonsGroup}>
                    <button type='submit' className={styles.submit}>Sign Up</button>
                    <div className={styles.login}>I have an account. <span onClick={() => navigate('/sign_in')}>Login</span></div>
                </div>
            </form>
        </Login>
    );
}

export default SignUp;
