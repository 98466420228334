import React, { useEffect,useState } from 'react';
import Login from '../../components/login';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import styles from './verify_email.module.scss';

function SendVerify() {
    const [isLoading, setIsLoading] = useState(true);
    const [registredEmail, setRegistredEmail] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        if (email) {
            setRegistredEmail(email);
            setIsLoading(false);
        } else {
            navigate('/sign_in');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Login>
            {isLoading && <Loading />}
            <div className={styles.title}>Verify Your</div>
            <div className={styles.title}>Email Address</div>
            <div className={styles.description}>We have sent a verification link to <span>{registredEmail}</span></div>
        </Login>
    );
}

export default SendVerify;